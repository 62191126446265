import android3 from "@/assets/android3.png";
import flutter1 from "@/assets/flutter1.png";
import flutter2 from "@/assets/flutter2.png";
import flutter3 from "@/assets/flutter3.png";
import ios2 from "@/assets/ios2.png";
import ios3 from "@/assets/ios3.png";
import other1 from "@/assets/other1.png";
import React from "react";
import { FaAndroid, FaApple, FaMicrosoft } from "react-icons/fa";
import { RiFlutterFill } from "react-icons/ri";

export const navItems = [
    { label: "Home", href: "/" },
    //{ label: "Business", href: "/business" },
    { label: "Blog", href: "/blog" },
    { label: "Github", href: "https://github.com/jiwomdf" },
    { label: "Linkedin", href: "https://www.linkedin.com/in/katili-jiwo-adi-wiyono-380a31162/" },
    { label: "Mail", href: "mailto:katili.jiwo.work@gmail.com" },
];

export type FeaturesBlog = {
    type: string;
    iconColor: string;
    contents: {
        text: string;
        description: string;
        link: string;
        type: string;
        img: string | null;
    }[];
}[]


export const features: FeaturesBlog = [
    {
        type: "Android Development",
        iconColor: "#0f9787",
        contents: [
            {
                text: "Android Jetpack Pro",
                description:
                    "Dicoding Android Jetpack Pro",
                link: "https://www.dicoding.com/users/670350/academies",
                type: "Android",
                img: null
            },
            {
                text: "Menjadi Android Developer Expert",
                description:
                    "Dicoding Android Developer Expert certificate",
                link: "https://www.dicoding.com/certificates/N9ZO7EL1DZG5",
                type: "Android",
                img: android3
            },
        ]
    },
    {
        type: "iOS Development",
        iconColor: "#A2AAAD",
        contents: [
            {
                text: "Belajar Fundamental Aplikasi iOS",
                description:
                    "Dicoding learning fundamental iOS app certificate",
                link: "https://www.dicoding.com/certificates/NVP781Q5GXR0",
                type: "iOS",
                img: ios2
            },
            {
                text: "Menjadi iOS Developer Expert",
                description:
                    "Dicoding iOS Developer Expert certificate",
                link: "https://www.dicoding.com/certificates/EYX4YL3Y5ZDL",
                type: "iOS",
                img: ios3
            },
        ]
    },
    {
        type: "Flutter Development",
        iconColor: "#027DFD",
        contents: [
            {
                text: "Belajar Membuat Aplikasi Flutter untuk Pemula",
                description:
                    "Dicoding learning how to create Flutter app for beginner certificate",
                link: "https://www.dicoding.com/certificates/RVZKRK9NEPD5",
                type: "Flutter",
                img: flutter1
            },
            {
                text: "Belajar Fundamental Aplikasi Flutter",
                description:
                    "Dicoding learning Fundamental Flutter app certificate",
                link: "https://www.dicoding.com/certificates/4EXGKJY49ZRL",
                type: "Flutter",
                img: flutter2
            },
            {
                text: "Menjadi Flutter Developer Expert",
                description:
                    "Dicoding Flutter Developer Expert certificate",
                link: "https://www.dicoding.com/certificates/JMZV34WDOPN9",
                type: "Flutter",
                img: flutter3
            },
        ]
    },
    {
        type: "Other",
        iconColor: "#BDDDFF",
        contents: [
            {
                text: "Belajar Dasar Git dengan GitHub",
                description:
                    "Dicoding learning Git with GitHub Sertificate",
                link: "https://www.dicoding.com/certificates/4EXG6G1MGZRL",
                type: "Other",
                img: other1
            },
        ]
    },
];

export type ChecklistItems = {
    app: string,
    jobDesc: string,
    place: string,
    description: string,
    chip: string[],
    icon: React.ReactElement,
    iconColor: string
}[]


export const portfolioItems: ChecklistItems = [
    {
        app: "Bina Nusantara Binus Maya School",
        jobDesc: "Full Stack .NET Developer",
        place: "IT Division Bina Nusantara",
        description:
            "I have work as Full Stack Programmer in IT Division Bina Nusantara who has responsibility in developing BINUS Maya Application that used by staff, teacher, student, and parent for Binus School. Working from designing the database until it use as a website for the user",
        chip: [
            "ASP.NET",
            "SQL Server",
            "Query",
            "HTML",
            "CSS",
            "Javascript",
            "C#",
        ],
        icon: <FaMicrosoft />,
        iconColor: "#00A4EF"
    },
    {
        app: "By.U Salvo Tools Android App",
        jobDesc: "Android Native Developer",
        place: "By.U",
        description:
            "I have work as Android Developer in by.U who has responsibility in developing Salvo Tools Application.",
        chip: [
            "Android Native",
            "Kotlin",
            "Java",
            "Jetpack Compose",
            "Firebase",
            "Fastlane",
            "App Tester",
            "CICD"
        ],
        icon: <FaAndroid />,
        iconColor: "#0f9787"
    },
    {
        app: "BRI & BNI Agent App [EDC Device]",
        jobDesc: "Android Native Developer",
        place: "PCS",
        description:
            "Develop, Maintained, and Shipped BNI, BRI Agent App in EDC Sunmi Device, work close with Product, Backend, UIUX, and QA Team",
        chip: [
            "Android Native",
            "Kotlin",
            "Java",
            "Web Socket"
        ],
        icon: <FaAndroid />,
        iconColor: "#0f9787"
    },
    {
        app: "Koinworks Android App",
        jobDesc: "Android Native Developer",
        place: "Koinworks",
        description:
            "Developed, Maintained, and Shipped Android Native App, work close with Product, Backend, UIUX, and QA Team",
        chip: [
            "Android Native",
            "Jetpack Compose",
            "Kotlin",
            "Java",
            "Web Socket",
            "Firebase",
            "Fastlane",
            "App Tester",
            "CICD",
            "Unit Testing",
        ],
        icon: <FaAndroid />,
        iconColor: "#0f9787"
    },
    {
        app: "Koinworks iOS App",
        jobDesc: "iOS Native Developer",
        place: "Koinworks",
        description:
            "Developed, Maintained, and Shipped iOS Native App, work close with Product, Backend, UIUX, and QA Team",
        chip: [
            "iOS Native",
            "Swift",
            "XIB",
            "Firebase",
            "App Tester",
            "CICD",
            "Unit Testing",
        ],
        icon: <FaApple />,
        iconColor: "#A2AAAD"
    },
    {
        app: "Migo Android App",
        jobDesc: "Android Native Developer",
        place: "MNC",
        description:
            "Developed, Maintained, and Shipped Android Native App, work close with Product, Backend, UIUX, and QA Team",
        chip: [
            "Android Native",
            "Kotlin",
            "Java",
            "Firebase",
        ],
        icon: <FaAndroid />,
        iconColor: "#0f9787"
    },
    {
        app: "Mr. Aladin Travel iOS App",
        jobDesc: "iOS Native Developer",
        place: "MNC",
        description:
            "Developed, Maintained, and Shipped iOS Native App, work close with Product, Backend, UIUX, and QA Team",
        chip: [
            "iOS Native",
            "Swift",
            "XIB",
            "Firebase",
            "App Tester",
            "CICD",
        ],
        icon: <FaApple />,
        iconColor: "#A2AAAD"
    },
    {
        app: "Aladin Mall Flutter App",
        jobDesc: "Flutter Developer",
        place: "MNC",
        description:
            "Developed, Maintained, and Shipped Flutter App, work close with Product, Backend, UIUX, and QA Team",
        chip: [
            "Flutter",
            "Dart",
            "Kotlin",
            "Swift",
            "Firebase",
            "App Tester",
            "CICD",
        ],
        icon: <RiFlutterFill />,
        iconColor: "#027DFD"
    },
    {
        app: "IPTV Hotel [Android TV Device]",
        jobDesc: "Android Native Developer",
        place: "MNC",
        description:
            "Developed, Maintained, and Shipped Android App, work close with Product, Backend, UIUX, and QA Team",
        chip: [
            "Android Native",
            "Android TV",
            "Kotlin",
            "Java",
            "Firebase",
        ],
        icon: <FaAndroid />,
        iconColor: "#0f9787"
    },
];

export const pricingOptions = [
    {
        jobDesc: "Free",
        price: "$0",
        features: [
            "Private board sharing",
            "5 Gb Storage",
            "Web Analytics",
            "Private Mode",
        ],
    },
    {
        jobDesc: "Pro",
        price: "$10",
        features: [
            "Private board sharing",
            "10 Gb Storage",
            "Web Analytics (Advance)",
            "Private Mode",
        ],
    },
    {
        jobDesc: "Enterprise",
        price: "$200",
        features: [
            "Private board sharing",
            "Unlimited Storage",
            "High Performance Network",
            "Private Mode",
        ],
    },
];

export const resourcesLinks = [
    { href: "#", text: "Getting Started" },
    { href: "#", text: "Documentation" },
    { href: "#", text: "Tutorials" },
    { href: "#", text: "API Reference" },
    { href: "#", text: "Community Forums" },
];

export const platformLinks = [
    { href: "#", text: "Features" },
    { href: "#", text: "Supported Devices" },
    { href: "#", text: "System Requirements" },
    { href: "#", text: "Downloads" },
    { href: "#", text: "Release Notes" },
];

export const communityLinks = [
    { href: "#", text: "Events" },
    { href: "#", text: "Meetups" },
    { href: "#", text: "Conferences" },
    { href: "#", text: "Hackathons" },
    { href: "#", text: "Jobs" },
];